import type { FC } from 'react';
import type { FeatureDefinition } from '@growthbook/growthbook-react';
import type { ISbApiResult, ISbStoriesParams, ISbStoryData } from '@storyblok/js';
import { getStoryblokApi, StoryblokComponent, useStoryblokState } from '@storyblok/react';
import type { GetStaticPaths, GetStaticProps, NextPage } from 'next';

import { usePaddleInit } from '@/hooks';
import type { MyAppProps } from '@/pages/_app';
import { Environment } from '@/types/index';

import { fetchGrowthbookFeatures } from '../context/Growthbook';

type PropsData<P> = {
  props: P;
}

export type PathParams = {
  slug: string[];
}

type SbPageProps = {
  sbData: {
    story: ISbStoryData | null;
    key: number | null;
  }
}

export type PageProps = Partial<SbPageProps> & Partial<IntegrationPageProps> & MyAppProps;

const SIXTY_SECONDS = 60;
const FIVE_SECONDS = 5;

const Slug: NextPage<PageProps> = ({ sbData, ...rest }) => {
  if (sbData) return <SbPage sbData={ sbData } { ...rest } />;

  return null;
};

const SbPage: FC<SbPageProps> = ({ sbData }) => {
  const storyData = useStoryblokState(sbData.story);
  usePaddleInit();

  return (
    <StoryblokComponent blok={ storyData?.content } />
  );
};

export const getStaticProps: GetStaticProps<PageProps, PathParams> = async ({ params }) => {
  if (!params) return { notFound: true };

  try {
    const { props } = await sbPageStaticProps(params);

    return {
      props,
      // Define revalidation time depending on the environment
      revalidate: process.env.NEXT_PUBLIC_VERCEL_ENV === Environment.Production ? SIXTY_SECONDS : FIVE_SECONDS,
    };
  } catch (e) {
    return { notFound: true };
  }
};

const sbPageStaticProps = async (params: PathParams | undefined): Promise<PropsData<SbPageProps & MyAppProps>> => {
  // home is the default slug for the homepage in Storyblok
  const slug = typeof params?.slug === 'object' ? params.slug.join('/') : 'home';

  const storyblokApi = getStoryblokApi();

  const sbParams: ISbStoriesParams = {
    version: `${ process.env.STORY_BLOK_VERSION }` as ISbStoriesParams['version'],
    resolve_relations: 'global_reference.reference',
  };

  const { data }: ISbApiResult = await storyblokApi.get(`cdn/stories/${ slug }`, sbParams);
  const hasABTest = data?.story?.content?.body?.length && data.story.content.body.find(blok => blok.component === 'ab_test');
  const growthBookFeatures: Record<string, FeatureDefinition> = hasABTest ? await fetchGrowthbookFeatures() : {};

  return {
    props: {
      sbData: {
        story: data ? data.story : null,
        key: data ? data.story.id : null,
      },
      growthBookFeatures,
    },
  };
}

export const getStaticPaths: GetStaticPaths<PathParams> = async () => {
  const paths: Array<{ params: PathParams }> = [];

  paths.push(...await getSbPagesPaths());

  return {
    paths,
    fallback: 'blocking',
  };
};

const getSbPagesPaths = async (): Promise<Array<{ params: PathParams }>> => {
  const paths: Array<{ params: PathParams }> = [];
  const storyblokApi = getStoryblokApi();
  const { data } = await storyblokApi.get('cdn/links/', {
    version: `${ process.env.STORY_BLOK_VERSION }` as ISbStoriesParams['version'],
  });

  Object.keys(data.links).forEach((linkKey) => {
    if (data.links[linkKey].is_folder) return;

    const { slug } = data.links[linkKey];
    const splitSlug = slug.split('/');

    paths.push({ params: { slug: splitSlug }});
  });

  return paths;
}

Slug.displayName = 'SB Page';
Slug.category = '';

export default Slug;
